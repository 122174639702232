import { call, put } from "@redux-saga/core/effects";
import { select } from "redux-saga/effects";
import * as actions from "../../actions/index.action";
import { selectState } from "../../../utils/selectors";
import { exportRecapitulatifFournisseurService } from "../../../services/documentDeclaration.service";
import { ERROR_EXPORT_RECAPITULATIF_VENTE } from "../../../utils/libelleConstantes";
import { formatDate_MMYYYY } from "../../../utils/dateUtil";


export function *exportRecapitulatifVenteSaga(action) {
    yield put(actions.setLoading(true));
    const state = yield select(selectState);
    const filtre = {
        gencodDeclarant: state.utilisateur.informations.gencod,
        annee: action.annee,
        moisAnneeDebut: formatDate_MMYYYY(action.moisAnneeDebut),
        moisAnneeFin: formatDate_MMYYYY(action.moisAnneeFin)
    };
    try {
        yield call(exportRecapitulatifFournisseurService, filtre);
        yield put(actions.setLoading(false));
    } catch (error) {
        yield put(actions.setLoading(false));
        yield put(actions.openDialogNoRedirect(ERROR_EXPORT_RECAPITULATIF_VENTE, error.message));
    }
}
