import { Box, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Trans } from "@lingui/macro";
import { Info } from "@material-ui/icons";
import { PROFIL_BIBLIOTHEQUE_COLOR, PROFIL_FOURNISSEUR_COLOR } from "../../../utils/constantes";
import LinkToLegiFrance from "../../link/linkToLegiFrance";
import logoSofia from "../../../assets/icons/logosofia.jpg";


export default function InformationDialog() {
    const [open, setOpen] = useState(false);
    const styles = {
        responsiveImage: {
            height: 100,
            paddingBottom: 20
        }
    };
    return <div>
        <IconButton onClick={() => setOpen(true)} title="Informations"><Info fontSize={"large"}/></IconButton>
        <Dialog open={open} fullWidth={true} maxWidth={"lg"} style={{ textAlign: "justify" }}>
            <DialogTitle>
                <div style={{ textAlign: "right" }}>
                    <IconButton onClick={() => setOpen(false)}><CloseIcon/></IconButton>
                </div>
                <div style={{ display: "block", justifyContent: "space-between", alignItems: "center", textAlign: "center" }}>
                    <img src={logoSofia} alt="" style={styles.responsiveImage}/>
                    <Typography variant="h4" gutterBottom ><b><Trans>informationTitle1</Trans></b></Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <Box>

                    <Typography variant="h6" gutterBottom >
                        <Trans>informationTitle2</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans id="informationBody2" components={[<LinkToLegiFrance premiereCo={false}/>]}/>
                        <br/>
                        <Link target="_blank"
                            href="https://www.la-sofia.org/la-sofia/"><Trans>informationBody2Bis</Trans></Link>
                    </Typography>
                    <br/>
                    <Typography variant="h6" gutterBottom >
                        <Trans>informationTitle3</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <span style={{ color: PROFIL_FOURNISSEUR_COLOR }}><b><Trans>informationBody3Fourni</Trans></b></span>
                        <Trans>informationBody3FourniBis</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <span style={{ color: PROFIL_BIBLIOTHEQUE_COLOR }}><b><Trans>informationBody3Bib</Trans></b></span>
                        <Trans>informationBody3BibBis</Trans>
                    </Typography>
                    <br/>
                    <Typography variant="h6" gutterBottom>
                        <Trans>informationTitle4</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody4</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody4Bis</Trans>
                    </Typography>
                    <br/>
                    <Typography variant="h6" gutterBottom >
                        <Trans>informationTitle5</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody5</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody5Bis</Trans>
                        <Link target="_blank"
                            href="https://www.la-sofia.org/droits-geres/droit-de-pret/">https://www.la-sofia.org/droits-geres/droit-de-pret/</Link>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans>informationBody5Ter</Trans>
                        <Link target="_blank"
                            href="mailto:droitdepret@la-sofia.org">droitdepret@la-sofia.org</Link>
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    </div>;
}
