import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import DateValidator from "../../validator/dateValidator";
import { LOWER_LIMIT_OF_DECLARATION, LOWER_LIMIT_OF_ENTITE_DATE_CREATION } from "../../../utils/constantes";
import { getDateFrenchSlashed } from "../../../utils/dateUtil";

export const isDateAfterBeginningDeclaration = "isDateBeforeBeginningDeclaration";
export const isNotDateAfterToday = "isNotDateAfterToday";
export const isValidDate = "isNotValidDate";
export const isNotDateBefore1753 = "isNotDateBefore1753"; // Limite technique du datetime sql dans laquelle est stockée la date dans DDP


class DatePickerValidatorComponent extends Component {
    // eslint-disable-next-line class-methods-use-this
    componentDidMount() {
        ValidatorForm.addValidationRule(isDateAfterBeginningDeclaration, value => !getDateFrenchSlashed(value).isBefore(LOWER_LIMIT_OF_DECLARATION));
        ValidatorForm.addValidationRule(isNotDateAfterToday, value => !getDateFrenchSlashed(value).isAfter(moment()));
        ValidatorForm.addValidationRule(isValidDate, value => getDateFrenchSlashed(value).isValid());
        ValidatorForm.addValidationRule(isNotDateBefore1753, value => !getDateFrenchSlashed(value).isBefore(LOWER_LIMIT_OF_ENTITE_DATE_CREATION));
    }


    // eslint-disable-next-line class-methods-use-this
    componentWillUnmount() {
        if (ValidatorForm.hasValidationRule) {
            if (ValidatorForm.hasValidationRule(isNotDateAfterToday)) {
                ValidatorForm.removeValidationRule(isNotDateAfterToday);
            }
            if (ValidatorForm.hasValidationRule(isDateAfterBeginningDeclaration)) {
                ValidatorForm.removeValidationRule(isDateAfterBeginningDeclaration);
            }
            if (ValidatorForm.hasValidationRule(isValidDate)) {
                ValidatorForm.removeValidationRule(isValidDate);
            }
            if (ValidatorForm.hasValidationRule(isNotDateBefore1753)) {
                ValidatorForm.removeValidationRule(isNotDateBefore1753);
            }
        }
    }

    render() {
        return <DateValidator {...this.props}/>;
    }
}

export default DatePickerValidatorComponent;
