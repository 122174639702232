import moment from "moment";

export const DATE_FORMAT_FRENCH_SLASHED = "DD/MM/YYYY";
export const DATE_FORMAT_FROM_BACK = "DD/MM/YYYY";
export const DATE_TIME_FORMAT_FROM_BACK = "DD/MM/YYYY HH:mm:ss";
export const TIME_FORMAT = "HH:mm:ss";
export const DATE_FORMAT_YEAR_MONTH = "YYYY-MM";
export const DATE_FORMAT_MONTH_YEAR_SLASHED = "MM/YYYY";

export const getDateFrenchSlashed = value => moment(value, DATE_FORMAT_FRENCH_SLASHED, true);
export const getDateFromBack = value => moment(value, DATE_FORMAT_FROM_BACK, true);
export const getDatetimeFromBack = value => moment(value, DATE_TIME_FORMAT_FROM_BACK, true);

export const formatDateTime_DDMMYYYYHHMMSS = date => date ? getDatetimeFromBack(date).format(`${DATE_FORMAT_FRENCH_SLASHED} - ${TIME_FORMAT}`) : "";
export const formatDateTime_HHMMSS = date => date ? getDatetimeFromBack(date).format(TIME_FORMAT) : "";
export const formatDateTime_DDMMYYYY = date => date ? getDatetimeFromBack(date).format(DATE_FORMAT_FRENCH_SLASHED) : "";
export const formatDate_DDMMYYYY = date => date ? getDateFromBack(date).format(DATE_FORMAT_FRENCH_SLASHED) : "";
export const formatDate_MMYYYY = date => date ? date.format(DATE_FORMAT_YEAR_MONTH) : "";
