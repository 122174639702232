// Clef des Libellés coté front

// Menus
export const LIBELLE_MON_PROFIL = "monProfil";
export const LIBELLE_NOUVELLE_DECLARATION = "nouvelleDeclaration";
export const LIBELLE_MES_FACTURES = "mesFactures";
export const LIBELLE_COMMUNICATION = "communication";
export const LIBELLE_COMMUNICATION_DDP = "communicationDdp";
export const LIBELLE_COMMUNICATION_ACTUS = "communicationActus";
export const LIBELLE_MES_DECLARATIONS = "mesDeclarations";
export const LIBELLE_MES_STATISTIQUES = "mesStatistiques";
export const LIBELLE_ECARTS_BIB_FOUR = "ecartBibFour";
export const LIBELLE_ADMINISTRATIF = "administratif";
export const LIBELLE_MES_PARTENAIRES_BIB = "mesPartenairesBib";
export const LIBELLE_MES_PARTENAIRES_FOURNI = "mesPartenairesFourni";
export const LIBELLE_AJOUTER_PARTENAIRE_BIB = "ajouterPartenaireBib";
export const LIBELLE_AJOUTER_PARTENAIRE_FOURNI = "ajouterPartenaireFourni";
export const LIBELLE_ATTESTATION_FIN_DECALARATION = "attestationFinDeclarations";
export const LIBELLE_RECAPITULATIF_VENTES = "recapitulatifVente";
export const LIBELLE_PREMIERE_CONNEXION = "renseignement";
export const LIBELLE_PARTENAIRE_DECLARES_REFERENCES = "partenairesDeclaresReferences";

// General
export const LIBELLE_CHAMP_OBLIGATOIRE = "mandatoryField";
export const LIBELLE_CIVILITE_OBLIGATOIRE = "mandatoryCivilityField";
export const LIBELLE_FORMAT_INCORRECT = "incorrectFormat";
export const LIBELLE_SAUVEGARDER = "sauvegarder";
export const LIBELLE_ENVOYER = "send";
export const LIBELLE_NEXT = "next";
export const LIBELLE_SAUVEGARDER_MAIL = "modifyMail";
export const LIBELLE_ERREUR_TELEPHONE_FORMAT = "erreurTelephoneFormat";
export const LIBELLE_TOOLTIP_TELEPHONE = "tooltipTelephone";
export const LIBELLE_NO_IDENTIQUE_MAIL = "noIdentiqueMail";
export const LIBELLE_RAISON_SOCIALE = "raisonSociale";
export const LIBELLE_INFO_DECLARANT_INTEGRATION = "infoDeclarantIntegration";
export const LIBELLE_TOTAL_TTC_3_ANS = "totalTtc3Ans";
export const LIBELLE_TYPE = "type";
export const LIBELLE_EN_ACTIVITE = "enActivite";
export const LIBELLE_FERMEE = "fermee";
export const LIBELLE_DATE_FERMETURE = "dateFermeture";
export const LIBELLE_STATUT = "statut";
export const LIBELLE_CODE_POSTAL_MIN_LENGTH = "erreurCodePostalMinLength";
export const LIBELLE_ERREUR_DATE_FUTURE = "erreurDateAfterCurrentDate";
export const LIBELLE_ERREUR_DATE_PASSE = "erreurDateBefore1753";
export const LIBELLE_OR = "or";
export const LIBELLE_OR2 = "or2";
export const LIBELLE_AND_OR = "andor";
export const LIBELLE_NON_RECONNU = "nonReconnu";
export const LIBELLE_NON_UTILISE = "nonUtilise";
export const LIBELLE_LIST_DECLARATION = "integrationDeclarationListeDeclaration";
export const LIBELLE_VALIDER = "validate";
export const LIBELLE_CONTINUER = "continuer";
export const LIBELLE_RECHERCHER = "rechercher";
export const LIBELLE_RECHERCHER_PAR_GLN = "rechercherByGln";
export const LIBELLE_ANNEE = "annee";
export const LIBELLE_PARENTHESE_OUVRANTE = " (";
export const LIBELLE_PARENTHESE_FERMANTE = ")";
export const LIBELLE_DETAILS = "details";
export const LIBELLE_FERMER = "close";
export const LIBELLE_EFFACER = "clear";
export const LIBELLE_TELECHARGER = "downloadFile";
export const LIBELLE_OPEN_FILE = "openFile";
export const LIBELLE_SOURCES = "sources";
export const LIBELLE_AUCUNE = "aucune";
export const LIBELLE_FOOTER_RGPD = "footerRgpd";
export const LIBELLE_FOOTER_MENTIONS_LEGALES = "footerMentionsLegales";
export const LIBELLE_FOOTER_CONDITIONS_UTILISATION = "footerConditionsUtilisation";
export const LIBELLE_FOOTER_COOKIE = "footerCookie";
export const LIBELLE_FOOTER_POLITIQUE_CONFIDENTIALITE = "footerPolitiqueConfidentialite";
export const LIBELLE_GERE_PAR = "gerePar";
export const LIBELLE_REGROUPE = "regroupe";
export const LIBELLE_REMPLACE = "remplace";
export const LIBELLE_COMPTE_CLOS = "compteClos";
export const LIBELLE_NON_ASSUJETTI = "nonAssujetti";
export const LIBELLE_OUI = "agreeLabel";
export const LIBELLE_NON = "disagreeLabel";
export const LIBELLE_ALERTE = "Alerte";
export const LIBELLE_ALERT_DATA_NOT_SAVED = "alertDataNotSaved";
export const LIBELLE_SE_DECONNECTER = "seDeconnecter";
export const LIBELLE_ACCUEIL = "accueil";
export const LIBELLE_ACCUEIL_FOURNISSEUR = "accueilFournisseur";
export const LIBELLE_ACCUEIL_BIBLIOTHEQUE = "accueilBibliotheque";
export const LIBELLE_NOTIFICATIONS = "notifications";

export const LIBELLE_INSCRIPTION_ALERT_DATA_NOT_SAVED_INSIDE_RESULT_GLN = "alertDataNotSavedResultGln";
export const LIBELLE_OPERATION_EN_COURS = "operationAlert";

export const LIBELLE_NUMERO = "numero";


export const LIBELLE_INFORMATION_URL_LEGIFRANCE = "infoLegiFrance";
// Mon profil
export const LIBELLE_NUMERO_VOIE = "numeroVoie";
export const LIBELLE_PRE_ADRESSE = "preAdresse";
export const LIBELLE_ADRESSE = "adresse";
export const LIBELLE_ADRESSE_1 = "adresse1";
export const LIBELLE_ADRESSE_2 = "adresse2";
export const LIBELLE_RAISON_SOCIALE_1 = "raison1";
export const LIBELLE_NOM_COMMERCIAL = "raison2";
export const LIBELLE_CODE_POSTAL = "codeP";
export const LIBELLE_VILLE = "ville";
export const LIBELLE_PAYS = "pays";
export const LIBELLE_APE = "ape";
export const LIBELLE_FORME_JURIDIQUE = "formeJuridique";
export const LIBELLE_FORME_JURIDIQUE_AUTRE = "formeJuridiqueAutre";
export const LIBELLE_TYPE_FOURNISSEUR = "typeFournisseur";
export const LIBELLE_DATE_CREATION = "dateCreationTooltip";
export const LIBELLE_EDI_SSII = "ediSsii";
export const LIBELLE_SIEGE_SOCIAL = "siegeSocial";
export const LIBELLE_COURRIEL = "courriel";
export const LIBELLE_NOUVEAU_COURRIEL = "nouveauCourriel";
export const LIBELLE_NOUVEAU_COURRIEL_CONFIRME = "nouveauCourrielConfirme";
export const LIBELLE_TELEPHONE = "tel";
export const LIBELLE_SITE_WEB = "siteweb";
export const LIBELLE_NOM = "nom";
export const LIBELLE_PRENOM = "prenom";
export const LIBELLE_QUALITE = "qualite";
export const LIBELLE_FONCTION = "fonction";
export const LIBELLE_PROFIL_SAISI = "profileSaisie";
export const LIBELLE_NUMERO_SIRET = "numSiret";
export const LIBELLE_NUMERO_SIREN_OR_SIRET = "numeroSirenOrSiret";
export const LIBELLE_GLN = "gln";
export const LIBELLE_A_PRECISER = "aPreciser";
export const LIBELLE_MOT_DE_PASSE = "pwd";
export const LIBELLE_MON_PROFIL_DUPLIQUER_INFOS_ENTITE = "monProfilContactSameData";
export const LIBELLE_MON_PROFIL_CONTACT_INFO_RDP = "monProfilContactInfoRdp";
export const LIBELLE_MON_PROFIL_CONTACT_COORDONNEES_TEMPORAIRES = "monProfilContactCoordonneesTemporaires";
export const LIBELLE_MON_PROFIL_CONTACT_MAIL_TEMPORAIRE = "monProfilContactMailTemporaire";
export const LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES = "monProfilInfosDonneesTemporaires";
export const LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES_NON_RDP = "monProfilInfosDonneesTemporairesNonRdp";
export const LIBELLE_MON_PROFIL_INFOS_DONNEES_TEMPORAIRES_RDP = "monProfilInfosDonneesTemporairesRdp";

export const LIBELLE_MAIL_DEMANDEUR = "mailDemandeur";
export const LIBELLE_INSCRIPTION_INFO_DECLARANT = "inscriptionInfoDeclarant";

export const LIBELLE_MON_PROFIL_ERREUR_CHARGEMENT = "errorLoadMonProfil";
export const LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_CONTACT = "monProfilErreurSauvegardeContact";
export const LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_ENTITE = "monProfilErreurSauvegardeEntite";
export const LIBELLE_MON_PROFIL_ERREUR_CHECK_MAIL = "mailKo";
export const LIBELLE_MON_PROFIL_ERREUR_SAUVEGARDE_OPTIONS_FACTURATION = "monProfilErreurSauvegardeOptionsFacturation";
export const LIBELLE_CIVLITE = "civilite";
export const LIBELLE_MON_PROFIl_MODE_FACTURATION = "monProfilModeFacturation";
export const LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO = "monProfilModeFacturationPremierCo";
export const LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO2 = "monProfilModeFacturationPremierCo2";
export const LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO_URL = "monProfilModeFacturationPremierCoUrl";
export const LIBELLE_MON_PROFIL_INFO_CONTACT_DFA = "monProfilInfoContactDFA";
export const LIBELLE_MON_PROFIL_MODIFICATION_MAIL_SUCCESS = "monProfilModificationMailSuccess";
export const LIBELLE_MON_PROFIL_MODIFICATION_MAIL_IDENTIQUE = "monProfilModificationMailIdentique";
export const LIBELLE_MON_PROFIL_ERREUR_MODIFICATION_MAIL = "monProfilErreurModificationMail";
export const LIBELLE_ERREUR_RECUPERATION = "libelleErreurRecuperation";
export const LIBELLE_MON_PROFIL_SAUT_ETAPE_DFA = "monProfilSautEtapeDFA";
export const LIBELLE_MON_PROFIL_SAUT_ETAPE_RL = "monProfilSautEtapeRL";
export const LIBELLE_MON_PROFIL_SAUT_ETAPE_RDP = "monProfilSautEtapeRDP";
export const MODIFICATION_MAIL_SUCCESS = "modificationMailSuccess";
export const MODIFICATION_MAIL_IDENTIQUE = "modificationMailIdentique";
export const MODIFICATION_MAIL_FAILED = "modificationMailFailed";
export const LIBELLE_MON_PROFIL_CONTACT_INFO_DFA = "monProfilContactInfoDfa";
export const LIBELLE_MON_PROFIL_UPDATE_INFO_DFA = "monProfilUpdateDFA";
export const LIBELLE_MON_PROFIL_OPTION_FACTURATION_ALERTE = "monProfilOptionFacturationAlerte";
export const LIBELLE_MON_PROFIL_INFORMATIONS_ENTITE = "entite";
export const LIBELLE_MON_PROFIL_INFORMATIONS_RDP = "responsableDroitDePret";
export const LIBELLE_MON_PROFIL_INFORMATIONS_RL = "representantLegal";
export const LIBELLE_MON_PROFIL_INFORMATIONS_DFA = "responsableSuiviFactures";
export const LIBELLE_MON_PROFIL_CHANGEMENT_MDP = "motDePasse";
export const LIBELLE_MON_PROFIL_OPTIONS_FACTURATION = "optionsFacturation";
export const LIBELLE_MON_PROFIL_INFORMATIONS_SOFIA = "infoSofia";
export const LIBELLE_MON_PROFIL_VALIDATION_MAIL_FOUR = "monProfilPremiereConnexionMailFour";
export const LIBELLE_MON_PROFIL_VALIDATION_MAIL_BIB = "monProfilPremiereConnexionMailBib";
export const LIBELLE_MON_PROFIL_VALIDATION = "monProfilPremiereConnexionValidation";
export const LIBELLE_MON_PROFIL_MDP_EXPLICATIONS = "monProfilMdpExplications";

export const LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_BIBS = "errorLoadBibs";
export const LIBELLE_MES_PARTENAIRES_ERREUR_CHARGEMENT_FOURNIS = "errorLoadFournis";
export const LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_BIBS = "errorExportBibs";
export const LIBELLE_MES_PARTENAIRES_ERREUR_EXPORT_FOURNIS = "errorExportFournis";
export const LIBELLE_MES_PARTENAIRES_EXPORTER = "exporterPartenaires";

// Type et Codes des Libellés coté back
export const LIBELLE_TYPE_FORME_JURIDIQUE_FOURNISSEUR = "forme_juridique_fournisseur";
export const LIBELLE_TYPE_FORME_JURIDIQUE_BIBLIOTHEQUE = "forme_juridique_bibliotheque";
export const LIBELLE_TYPE_TYPE_FOURNISSEUR = "type_fournisseur";
export const LIBELLE_TYPE_PAYS = "pays";
export const LIBELLE_TYPE_FONCTION_CONTACT = "function_contact";
export const LIBELLE_TYPE_DECLARATION = "type_declaration";
export const LIBELLE_TYPE_CIVILITE = "civilite";

export const LIBELLE_TYPE_FOURNISSEUR_CODE_AUTRE = "S";
export const LIBELLE_TYPE_FOURNISSEUR_CODE_TEST = "T";
export const LIBELLE_TYPE_FOURNISSEUR_CODE_INCONNU = "A";
export const LIBELLE_FORME_JURIDIQUE_CODE_AUTRE = "99";
export const LIBELLE_FONCTION_CONTACT_CODE_RDP = "RDP";
export const LIBELLE_FONCTION_CONTACT_CODE_DFA = "DFA";
export const LIBELLE_FONCTION_CONTACT_CODE_RL = "RL";

// Mes partenaires
export const LIBELLE_ADD_TO_FAVORITES_TOOLTIP_BIB = "addToFavoritesBib";
export const LIBELLE_ADD_TO_FAVORITES_TOOLTIP_FOUR = "addToFavoritesFour";
export const LIBELLE_REMOVE_FROM_FAVORITES_TOOLTIP_BIB = "removeFromFavoritesBib";
export const LIBELLE_REMOVE_FROM_FAVORITES_TOOLTIP_FOURNI = "removeFromFavoritesFourni";

// Mes déclarations
export const LIBELLE_MES_DECLARATIONS_TOTAL_HT = "mesDeclarationsTotalHT";
export const LIBELLE_MES_DECLARATIONS_TOTAL_TTC = "mesDeclarationsTotalTTC";
export const LIBELLE_MES_DECLARATIONS_TOTAL_LIVRE_HT = "mesDeclarationsTotalLivreHT";
export const LIBELLE_MES_DECLARATIONS_TOTAL_DDP = "mesDeclarationsTotalDDP";
export const LIBELLE_MES_DECLARATIONS_TOTAL_PPHT = "mesDeclarationsTotalPPHT";
export const LIBELLE_MES_DECLARATIONS_TOTAL_PPHTFEL = "mesDeclarationsTotalPPHTFEL";
export const LIBELLE_MES_DECLARATIONS_TOTAL_PPTTC = "mesDeclarationsTotalPPTTC";
export const LIBELLE_MES_DECLARATIONS_NOMBRE_LIVRES = "mesDeclarationsNombreLivres";
export const LIBELLE_MES_DECLARATIONS_STATISTIQUES = "mesDeclarationsStatsDeclarations";
export const LIBELLE_MES_DECLARATIONS_FILTRES_EXPLICATIONS = "mesDeclarationsFiltresExplications";
export const LIBELLE_MES_DECLARATIONS_FILTRES_ANNEE_ENVOI = "mesDeclarationsFiltresAnneeEnvoi";
export const LIBELLE_MES_DECLARATIONS_FILTRES_ANNEE_FACTURATION = "mesDeclarationsFiltresAnneeFacturation";
export const LIBELLE_MES_DECLARATIONS_FILTRES_ANNEE_PIECE = "mesDeclarationsFiltresAnneePiece";
export const LIBELLE_MES_DECLARATIONS_ENVOYER_DATE = "mesDeclarationsEnvoyerDate";
export const LIBELLE_MES_DECLARATIONS_ENVOYER_DATE_ET_IGNORER = "mesDeclarationsEnvoyerDateEtIgnorer";
export const LIBELLE_MES_DECLARATIONS_ENVOYER_DATE_VIDE = "mesDeclarationsEnvoyerDateVide";
export const LIBELLE_MES_DECLARATIONS_ENVOYER_FILTRES = "mesDeclarationsEnvoyerFiltrees";
export const LIBELLE_MES_DECLARATIONS_ENVOYER_FILTRES_POPUP = "mesDeclarationsEnvoyerFiltreesPopup";
export const LIBELLE_MES_DECLARATIONS_FILTRES_EXPLICATIONS_DDP = "mesDeclarationsFiltresExplicationsDdp";
export const LIBELLE_MES_DECLARATIONS_INFOS = "mesDeclarationsInfo";
export const LIBELLE_MES_DECLARATIONS_BIBS = "mesDeclarationsSelectAllBibs";
export const LIBELLE_MES_DECLARATIONS_FOURNIS = "mesDeclarationsSelectAllFournis";

// Déclaration
export const LIBELLE_DECLARATION_SAISIE_EN_LIGNE = "saisieEnLigne";
export const LIBELLE_DECLARATION_INTEGRATION_EXCEL = "tableur";
export const LIBELLE_ENVOYER_A_SOFIA = "envoyerDeclarationASofia";

export const LIBELLE_DATE_PIECE_OBLIGATORIE = "dateRequired";
// Référencement
export const LIBELLE_DEMANDE_REFERENCEMENT = "demandeReferencement";

export const LIBELLE_REFERENCEMENT_STEP_0 = "step0_referencement";

export const LIBELLE_REFERENCEMENT_STEP_2 = "step2_referencement";
export const LIBELLE_REFERENCEMENT_STEP_4 = "step3_referencement";

// Accueil
export const LIBELLE_ACCUEIL_BIENVENUE = "accueilBienvenue";
export const LIBELLE_ACCUEIL_PARTENAIRES1 = "accueilPartenaires1";
export const LIBELLE_ACCUEIL_PARTENAIRES2 = "accueilPartenaires2";


export const LIBELLE_MAIL_OBLIGATOIRE = "mailObligatoire";
export const LIBELLE_MAIL_REFERENCE = "mailReference";
export const LIBELLE_ADRESSE_MAIL = "adressemail";
export const LIBELLE_GLN_MDP_OUBLIE = "glnMdpOublie";
export const LIBELLE_SEND_REQUEST = "sendRequest";
export const LIBELLE_ERREUR_GLN_LENGTH = "erreurGlnLength";
export const LIBELLE_ERREUR_URL_VALIDATION = "erreurUrlValidation";
export const LIBELLE_GLN_OBLIGATOIRE = "glnObligatoire";

// Intégration Excel
export const LIBELLE_INTEGRATION_TYPE_FICHIER_INCORRECT = "fileTypeErreur";
export const LIBELLE_INTEGRATION_TYPES_FICHIER_ACCEPTES = "fileTypeAccepted";
export const LIBELLE_INTEGRATION_RECAPITULATIF_DOWNLOAD_ERROR = "integrationRecapitulatifDownloadErreur";
export const LIBELLE_CORRIGER_AVANT_CREATION_DECLARATION = "corrigerAvantCreationDeclaration";
export const LIBELLE_SAUVEGARDER_AVANT_CREATION_DECLARATION = "sauvegarderAvantCreationDeclaration";
export const LIBELLE_INTEGRATION_DECLARATION_CORRECTION_CREER_DECLARATION = "integrationDeclarationCorrectionCreerDeclaration";
// Intégration choix entête
export const LIBELLE_DELETE_INTEGRATION = "deleteIntegration";
export const LIBELLE_CONFIRM_DELETE_INTEGRATION = "confirmDeleteIntegration";
export const LIBELLE_CHOIX_ENTETES_OBLIGATOIRES = "integrationDeclarationChoixEntetesObligatoires";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_TITLE = "integrationBlocFormatTitle";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_INFOS_FOURNISSEUR = "integrationBlocFormatInfosFournisseur";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_INFOS_FOURNISSEUR2 = "integrationBlocFormatInfosFournisseur2";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_INFOS_BIBLIOTHEQUE = "integrationBlocFormatInfosBibliotheque";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_INFOS_BIBLIOTHEQUE2 = "integrationBlocFormatInfosBibliotheque2";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_ERREUR_DOWNLOAD_MODELE = "integrationBlocFormatErreurDownloadModele";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_DOWNLOAD_MODELE = "integrationBlocFormatDownloadModele";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_ERREUR_PREFACE = "integrationBlocFormatErreurPreface";
export const LIBELLE_INTEGRATION_BLOC_FORMAT_INFOS_FICHIER = "integrationBlocFormatInfosFichier";

// Mes partenaires favoris
export const LIBELLE_INFO_FAVORIS_FOUR = "infoOrgaFavorisFour";
export const LIBELLE_INFO_FAVORIS_BIB = "infoOrgaFavorisBib";
export const LIBELLE_INFO_RECENTS_FOUR = "infoOrgaRecentsFour";
export const LIBELLE_INFO_RECENTS_BIB = "infoOrgaRecentsBib";
export const LIBELLE_INFO_FAVORIS_VIDE = "infoFavorisVide";

// Attestation fin déclaration
export const LIBELLE_ATTESTATION_FIN_DECLARATION_ECART_IMPORTANT = "attestationFinDeclarationEcartImportant";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_TELECHARGEMENT = "attestationFinDeclarationJeTelecharge";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_BOUTON = "attestationFinDeclarationBouton";
export const LIBELLE_NON_APPLICABLE = "nonApplicable";


export const LIBELLE_ATTESTATION_FIN_DECLARATION_NO_CONTACT = "attestationFinDeclarationNoContact";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_JE_SOUSSIGNE = "attestationFinDeclarationJeSoussigne";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_FONCTION = "attestationFinDeclarationFonction";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_RAISON_SOCIALE = "attestationFinDeclarationRaisonSociale";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_ANNEE = "attestationFinDeclarationAnnee";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_MONTANT_FORM_BIB = "attestationFinDeclarationMontantFormBib";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_MONTANT_FORM_FOURNI = "attestationFinDeclarationMontantFormFourni";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_DEFAUT_CONTACT = "attestationFinDeclarationDefautContact";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_AVERTISSEMENT3_FOURNI = "attestationFinDeclarationAvertissement3Fourni";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_AVERTISSEMENT3_BIB = "attestationFinDeclarationAvertissement3Bib";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_AVERTISSEMENT2 = "attestationFinDeclarationAvertissement2";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_AVERTISSEMENT1 = "attestationFinDeclarationAvertissement1";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_CHECKBOX_MONTANT_NUL_BIB = "attestationFinDeclarationCheckboxMontantNulBib";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_CHECKBOX_MONTANT_NUL_FOURNI = "attestationFinDeclarationCheckboxMontantNulFourni";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_AVERTISSEMENT4_MONTANT_NUL_BIB = "attestationFinDeclarationAvertissement4MontantNulBib";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_AVERTISSEMENT4_MONTANT_NUL_FOURNI = "attestationFinDeclarationAvertissement4MontantNulFourni";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_CHECKBOX = "attestationFinDeclarationCheckbox";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_AVERTISSEMENT4 = "attestationFinDeclarationAvertissement4";
export const LIBELLE_ATTESTATION_FIN_DECLARATIONS = "attestationFinDeclarations";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_MONTANT_DECLARE = "attestationFinDeclarationMontantDeclare";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_DATE = "attestationFinDeclarationDate";
export const LIBELLE_ATTESTATION_FIN_DECLARATION_DOCUMENT = "attestationFinDeclarationDocument";


// Factures
export const DATE_FACTURE = "dateDeFacture";
export const NUMERO_FACTURE = "numeroFacture";
export const TOTAL_TTC = "totalTTC";
export const DATE_CREATION_FACTURE = "dateCreationFacture";
export const DATE_EMISSION_FACTURE = "dateEmissionFacture";
export const MONTANT_TTC = "montantTtc";
export const MONTANT_RESTE_A_PAYER = "montantARegler";
export const STATUT = "statutCalcule";
export const DATE_ECHEANCE = "dateEcheance";
export const ETAT = "etat";
export const DOCUMENT = "document";

// Login
export const LIBELLE_LOGIN_CONNEXION = "connexion";
export const LIBELLE_LOGIN_BIENVENUE_1 = "loginBienvenue1";
export const LIBELLE_LOGIN_BIENVENUE_2 = "loginBienvenue2";
export const LIBELLE_NOUS_CONTACTER = "contact";


// Nous Contacter
export const LIBELLE_TYPE_ENTITE = "typeEntite";
export const LIBELLE_EMAIL_STRUCTURE = "courrielStructure";
export const LIBELLE_TELEPHONE_STRUCTURE = "telStructure";
export const LIBELLE_MESSAGE = "message";

// Notification
export const LIBELLE_NOTIFICATION_NOUVELLE_FACTURE = "detectionNouvelleFacture";

// Recherche d'assujettis
export const LIBELLE_ANNUAIRE = "annuaire";
export const LIBELLE_INSCRIPTION = "inscription";
export const LIBELLE_RECHERCHE_ASSUJ_ENTITE = "entiteRecherche";
export const LIBELLE_RECHERCHE_ASSUJ_ERREUR_CODE_POSTAL_LENGTH = "erreurCodePostalLength";
export const LIBELLE_RECHERCHE_ASSUJ_SEARCH = "search";
export const LIBELLE_RECHERCHE_ASSUJ_DEMANDE_REFERENCMENT = "demandeRefButton";
export const LIBELLE_REFERENCEMENT_FROM_SAISIE_WEB = "entityExistsDemandeReferencementSaisieWeb";
export const LIBELLE_REFERENCEMENT_BIB = "entityExistsDemandeReferencementBib";
export const LIBELLE_REFERENCEMENT_FOURNI = "entityExistsDemandeReferencementFourni";
export const LIBELLE_RECHERCHE_ASSUJ_DEMANDE_REFERENCEMENT_NEW_FOUR = "demandeRefNewButtonFour";
export const LIBELLE_RECHERCHE_ASSUJ_DEMANDE_REFERENCEMENT_NEW_BIB = "demandeRefNewButtonBib";
export const LIBELLE_RECHERCHE_ASSUJ_DEMANDE_REFERENCEMENT_SELECTED = "demandeRefSelectedButton";
export const LIBELLE_RECHERCHE_ASSUJ_INSCRIPTION = "createCompte";
export const LIBELLE_RECHERCHE_ASSUJ_RECHERCHE_DETAILLEE = "rechercheDetail";
export const TYPE_FOURNISSEUR = "type_fournisseur";
export const TYPE_BIBLIOTHEQUE = "type_bibliotheque";
export const LIBELLE_CHAINE_VIDE = "";
export const LIBELLE_TABLE_NO_RESULT = "tableNoResult";
export const LIBELLE_ERREUR_GLN = "erreurGLN";
export const LIBELLE_UNKNOWN_LIBRARY = "unknownLibraryGln";
export const LIBELLE_UNKNOWN_PROVIDER = "unknownProviderGln";
export const LIBELLE_ERREUR_FOURNISSEUR_COMPTE_CLOS = "fournisseurCompteClos";


// Un tableau qui représente les différentes parties du filAriane non cliquable, car on n'a pas d'écran de base
export const ITEMS_NON_CLIQUABLE = [LIBELLE_ADMINISTRATIF, LIBELLE_MON_PROFIL, LIBELLE_NOUVELLE_DECLARATION];

export const LIBELLE_MAX_MIN_NUMERO_SIREN_SIRET = "minMaxLengthNumeroSirenOuSiret";
export const LIBELLE_INVALID_NUMERO_SIREN = "invalidNumeroSiren";
export const LIBELLE_INVALID_NUMERO_SIRET = "invalidNumeroSiret";

// Inscription
export const LIBELLE_INSCRIPTION_CHOIX_PROFIL_DECLARANT = "inscriptionChoixProfilDeclarant";
export const LIBELLE_INSCRIPTION_PROFIL_FOURNISSEUR = "inscriptionProfilFournisseur";
export const LIBELLE_INSCRIPTION_PROFIL_FOURNISSEURS = "inscriptionProfilFournisseurs";
export const LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUE = "inscriptionProfilBibliotheque";
export const LIBELLE_INSCRIPTION_PROFIL_BIBLIOTHEQUES = "inscriptionProfilBibliotheques";
export const LIBELLE_INTEGRATION_CORRECTION_NB_ERREURS = "integrationDeclarationCorrectionNbErreurs";
export const LIBELLE_ATTENTION = "attentionTitre";
export const LIBELLE_ARTICLE_DES = "articleDes";
export const LIBELLE_ARTICLE_DU = "articleDu";
export const LIBELLE_ARTICLE_DE_L = "articleDeL";

export const LIBELLE_DELETE_FAVORI_NON_RECENT_BIB = "deleteFavoriNonRecentBib";
export const LIBELLE_DELETE_FAVORI_NON_RECENT_FOUR = "deleteFavoriNonRecentFour";
export const LIBELLE_DELETE_FAVORI_BIB = "deleteFavoriBib";
export const LIBELLE_DELETE_FAVORI_FOURNI = "deleteFavoriFourni";
export const LIBELLE_ANNULER = "cancel";

export const LIBELLE_EXPORT_ORGANISME_DE_PRET = "exporterOrganismesPret";
export const LIBELLE_EXPORT_FOURNISSEUR_DE_LIVRE = "exporterFournisseursLivres";


export const LIBELLE_ABANDONNER_DECLARATION = "cleanDeclaration";
export const LIBELLE_ANNULER_DECLARATION = "annulerDeclaration";
export const LIBELLE_ANNULER_DECLARATION_CONFIRM_MESSAGE = "annulerDeclarationConfirmMessage";
export const LIBELLE_DECLARATION_ANNULEE = "annulerDeclarationAnnulee";
export const LIBELLE_ANNULATION_DISABLED_DECLARATION_EN_COURS_ENVOI = "annulerDeclarationDisabledDeclarationEnCoursEnvoi";
export const ERREUR_ANNULER_DECLARATION = "annulerDeclarationError";

export const LIBELLE_SUPPRESSION_DECLARATION_MESSAGE = "supprimerDeclarationConfirmMessage";
export const LIBELLE_INTEGRATION_DECLARATION_SUIVANTE = "integrationDeclarationSuivanteLabel";
export const LIBELLE_INTEGRATION_DECLARATION_PRECEDANTE = "integrationDeclarationPrecedanteLabel";

export const LIBELLE_SUPPRIMER_MODIFICATION_DECLARATION_ENVOYE = "supprimerModificationDeclarationEnvoyee";
export const LIBELLE_MESSAGE_SUPPRIMER_MODIFICATION_DECLARATION_ENVOYE = "supprimerModificationDeclarationEnvoyeeMessage";

export const LIBELLE_MES_PARTENAIRES_AJOUT_BIB_NO_DUPLICATE = "mesPartenairesAjoutBibNoDuplicate";
export const LIBELLE_MES_PARTENAIRES_AJOUT_FOUR_NO_DUPLICATE = "mesPartenairesAjoutFourNoDuplicate";
export const LIBELLE_MES_PARTENAIRES_AJOUT_BIB_WITH_DUPLICATE = "mesPartenairesAjoutBibWithDuplicate";
export const LIBELLE_MES_PARTENAIRES_AJOUT_FOUR_WITH_DUPLICATE = "mesPartenairesAjoutFourWithDuplicate";

export const LIBELLE_PARTENAIRE_NOT_FOUND = "partenaireNotFound";
export const LIBELLE_ERROR_SYSTEME = "errorBadRequest";

export const LIBELLE_RECHERCHE_ANNULAIRE = "rechercheAnnuaire";
export const LIBELLE_TOOLTIP_AIDE_UTILISATEUR = "tooltipAideUtilisateur";

// Statistiques
export const TEMPS_MAX_EXPORT_LIGNES_EAN = "tempsMaxExportLignesEan";
export const TEMPS_MIN_EXPORT_LIGNES_EAN = "tempsMinExportLignesEan";


export const ERREUR_DOWNLOAD_ATTESTATION = "erreurDownloadAttestation";
export const ERREUR_GET_ATTESTATIONS = "erreurGetAttestations";
export const ERREUR_SEND_ATTESTATION = "erreurSendAttestation";
export const ERREUR_RECUPERER_LISTE_ECART = "erreurRecupererListeEcart";
export const ERREUR_VERIFIER_ECART = "erreurVerifierEcart";
export const ERREUR_SAUVEGARDER_DECLARATION = "erreurSauvegardeDeclaration";
export const ERREUR_VALIDER_DECLARATION_EN_ETAT = "erreurValidateOnDeclarationEnEtat";
export const ERREUR_ABANDONNER_DECLARATION = "erreurSuppressionDeclaration";
export const DEMANDE_REFERENCEMENT_DIALOG_FIN = "demandeReferencementDialogFin";
export const DEMANDE_REFERENCEMENT_ERROR = "demandeReferencementError";
export const ERROR_BAD_REQUEST = "errorBadRequest";
export const ERROR_INSCRIPTION = "erreurInscription";
export const ERROR_EXPORT_RECAPITULATIF_VENTE = "errorExportRecapitulatifVente";
export const ERROR_FILE_NAME_DIALOG_NOT_AVAILABLE = "fileNameDialogNameNotAvailableErrorMessage";
export const FILE_NAME_EXIST_VERIFICATION_FAILED = "fileNameExistenceVerificationFailed";

export const INSCRIPTION_VALIDEE = "inscriptionValidee";
export const INSCRIPTION_INVALIDE = "inscriptionInvalide";
export const ERREUR_INSCRIPTION = "erreurInscription";
export const CONFIRM_ENTETE_ERROR = "confirmEnteteError";
export const ERREUR_INTEGRATION_CORRECTION_CREATE_DECLARATION = "integrationDeclarationCorrectionErreurCreateDeclarationsError";

export const ERREUR_INTEGRATION_TABLEUR = "integrationTableurError";
export const ERREUR_INTEGRATION_BY_ID = "integrationDeclarationEnteteByIdIntegrationError";
export const ERREUR_INTEGRATION_LIGNE_BY_NUMERODECLARATION = "integrationDeclarationLigneByNumeroDeclarationError";

export const INTEGRATION_DECLARATION_SUIVANTE_LABEL = "integrationDeclarationSuivanteLabel";

export const ERREUR_INTEGRATION_DECLARATION_SEND = "integrationDeclarationSendIntegrationEnEtatError";

export const ERREUR_ID_INTEGRATION_FROM_NUMERODECLARATION = "erreurIdIntegrationFromNumeroDeclaration";
export const ERREUR_RECHERCHE_INFORMATIONS = "erreurRechercheInformations";
export const ERREUR_CHECK_IF_FOUR_CANMAKE_GLOBAL_DECLARATION = "erreurCheckIfFourCanMakeGlobalDeclaration";
export const ERREUR_CHECK_SIRET = "erreurCheckSiret";

export const ERREUR_EXPORT_MENUSUALISATION = "errorExportMensualisation";
export const ERREUR_GET_PARTENARES_FAVORIS = "erreurGetPartenairesFavoris";

export const ERREUR_EXPORT_LIGNES_EAN = "errorExportLignesEan";
export const ERREUR_COUNT_EXPORT_LIGNES_EAN = "errorCountExportLignesEan";

export const ERREUR_LOAD_FACTURES = "errorLoadFactures";
export const ERREUR_LOAD_PARTENAIRES = "errorLoadPartenaires";

export const SUCCESS_CHANGE_PWD = "successChangePwd";
export const NO_USER_CHANGE_PWD = "noUserChangePwd";

export const FAIL_CHANGE_PWD = "failChangePwd";
export const ERREUR_ALL_NOTIFICATIONS = "errorAllNotifications";
export const ERREUR_UPDATE_NOTIFICATIONS = "errorUpdateNotifications";
export const EMPTY_NOTIFICATIONS = "emptyNotifications";

export const NOUS_CONTACTER_ENVOI_SNACKBAR = "nousContacterEnvoiSnackbar";
export const ERREUR_NOUS_CONTACTER_SEND = "erreurSendNousContacter";
export const ERREUR_MON_PROFIL_ENVOI_CROISEMENT = "monProfilEnvoiCroisementError";
export const ERREUR_DOWNLOAD_FACTURE = "errorDownloadFacture";
export const ERREUR_MES_DECLARATIONS = "erreurMesDeclarations";
export const ERREUR_NOMBRE_DECLARATION_TYPE = "erreurNombreDeclarationParType";
export const ERREUR_INTEGRATION_DECLARATIONLIGNE_BY_NUMERODECLARATION = "integrationDeclarationLigneByNumeroDeclarationError";
export const ERREUR_MES_DECLARATIONS_SEND_ALL = "erreurMesDeclarationsSendAll";
export const ERREUR_MODIFIER_DECLARATION_FACTUREE = "modifyDeclarationFactureeError";
export const ERREUR_ENVOYER_DECLARATION = "sendDeclarationError";
export const ERREUR_NOMBRE_DECLARATION_PAR_TYPE = "erreurNombreDeclarationParType";

export const ERREUR_GET_TAUX_TVA_FOR_DECLARATION = "erreurGetTauxTvaForDeclaration";
export const ERREUR_GET_DECLARATION_LIGNE_BY_NUMERO_DECLARATION = "erreurGetDeclarationLigneByNumeroDeclaration";
export const ERREUR_INTEGRATION_CORRECTION_GET_ENTETES_CHOISIES = "integrationDeclarationCorrectionErreurGetEntetesChoisiesError";
export const ERREUR_INTEGRATION_CORRECTION_GET_LIGNES = "integrationDeclarationCorrectionErreurGetLignesError";
export const ERREUR_INTEGRATION_CORRECTION_SAVEETGET_LIGNES = "integrationDeclarationCorrectionErreurSaveGetLignesError";
export const ERREUR_INTEGRATION_CORRECTION_DELETEGET_STATS = "integrationDeclarationCorrectionErreurDeleteGetStatsError";

export const ERREUR_INTEGRATION_GET_PERCENTAGE = "erreurIntegrationGetPercentage";
export const ERREUR_GET_LISTE_URL_AIDE = "erreurGetListeUrlAide";

export const ERREUR_DATE_FIN = "invalidDateAfterDateFin";
export const ERREUR_DATE_DEBUT = "invalidDateBeforeDateDebut";
export const ERREUR_DATE_DEBUT_2013 = "invalidDateBeforeLimitLowerDate";
export const ERREUR_DATE_DEBUT_1970 = "erreurDateBefore1970";

export const LIBELLE_ERREUR_NUMERO_PIECE_TROP_GRAND = "erreurNumeroPieceTropGrand";

export const LIBELLE_EXPORT_MENSUALISATION = "exportMensualisation";
export const LIBELLE_EXPORT_LIGNES_EAN = "exportLignes";
export const LIBELLE_EXPORT_RECAPITULATIF_ACHATS = "exportRecapitulatifAchat";
export const LIBELLE_EXPORT_RECAPITULATIF_VENTES = "exportRecapitulatifVente";
