import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { defaultDatePickerProps } from "../../utils/objectUtil";
import PickerProvider from "./PickerProvider";

function DatePicker({ minDate, minDateMessage, ...props }) {
    return (
        <PickerProvider>
            <KeyboardDatePicker
                {...defaultDatePickerProps(minDate, minDateMessage)}
                {...props}
            />
        </PickerProvider>);
}

export default DatePicker;
