import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import React from "react";
import { withI18n } from "@lingui/react";
import moment from "moment";

function PickerProvider(props) {
    const { i18n } = props;
    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={i18n.locale}>
            {props.children}
        </MuiPickersUtilsProvider>);
}

export default withI18n(PickerProvider);
