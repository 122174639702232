import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import DatePicker from "../datePicker/DatePicker";

class DateValidator extends ValidatorComponent {
    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            errorMessages,
            // eslint-disable-next-line no-unused-vars
            validatorListener,
            helperText,
            variant,
            ...rest
        } = this.props;

        const { isValid } = this.state;
        const dateProps = {
            inputVariant: variant,
            error: !isValid,
            helperText: (!isValid && this.getErrorMessage()) || helperText,
            ...rest
        };
        return (
            <DatePicker {...dateProps}/>
        );
    }
}

export default DateValidator;
