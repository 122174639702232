import Warning from "@material-ui/icons/Warning";
import { Trans } from "@lingui/macro";
import React from "react";
import { MenuItem } from "@material-ui/core";
import { PATH_MENU_FACTURES } from "../../../routes/paths/paths.util";
import BaseNotification from "../../../commonComponents/layout/notification/base/baseNotification";
import { formatDate_DDMMYYYY } from "../../../utils/dateUtil";

export default function DossierEnContentieuxNotification({ notifications, callBack }) {
    return notifications.length > 0 && notifications.map(n => <MenuItem key={n.id}><BaseNotification
        suffixPath={PATH_MENU_FACTURES}
        color={"red"}
        icon={<Warning/>}
        callBack={callBack}
        dateCreation={n.dateCreation}
        label={<Trans
            values={{ gencod: n.gencod, date: formatDate_DDMMYYYY(n.dateMiseEnContentieux) }}>notificationDossierEnContentieux</Trans>}/></MenuItem>);
}
