import { Link } from "@material-ui/core";
import React from "react";
import { Trans } from "@lingui/macro";
import { LIBELLE_INFORMATION_URL_LEGIFRANCE, LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO_URL } from "../../utils/libelleConstantes";

export default function LinkToLegiFrance({ premiereCo = true }) {
    return premiereCo ? (
        <Link
            target="_blank"
            href="https://www.legifrance.gouv.fr/jorf/article_jo/JORFARTI000044044179">
            <Trans id={LIBELLE_MON_PROFIL_MODE_FACTURATION_PREMIERE_CO_URL} />
        </Link>
    ) : (
        <Link
            target="_blank"
            href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000411828">
            <Trans id={LIBELLE_INFORMATION_URL_LEGIFRANCE} />
        </Link>
    );
}
